/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState, useContext, useCallback } from "react";
import { Autocomplete } from "../../../components/autocomplete";

import { Field, Select, Option, Input } from "../../../components/form";
import { DmdcTitleAPIService } from "../../../services/title-api/dmdc-title-api.service";
import { useArray } from "../../../hooks";
import {
  TitleDetails,
  TitleVersion,
} from "../../../services/title-api/title-api.types";
import {
  DmdcChildEntity,
  SearchResult,
  SeriesChildren,
  TitleItem,
  ProductType,
  EpisodeTitles,
  ParentProductTypes,
  EventProductTypes,
  Version,
  screenerTypeOptions,
  DSScreenerTypeOptions,
  PreliminaryScreener,
  FinalScreener,
  FinalTrailer,
  TrailerForRatings,
  Promo,
} from "../../../services/title-api/dmdc-title-api.types";
import { Metadata } from "../../../services/upload-api/upload-api.types";
import {
  ToastContext,
  ToastContextDef,
  ToastType,
} from "../../../components/toast";

import { EpisodeField, SeasonField, VersionField } from "../form";
import { DropZoneFileDTO } from "../../../components/drop-zone";
import {
  mapRootTitle,
  mapSeasonTitle,
  mapEpisodeTitle,
  mapChildEntity,
  mapVersion,
} from "../../../utils/dmdcToDsMappings";
import { FileNameParser } from "../../../utils/FileNameParser";
import moment from "moment";

export type DmdcMetadataSectionProps = {
  onMetadataChanges: (meta: Metadata | undefined) => void;
  titleAPI: DmdcTitleAPIService;
  videoFile: DropZoneFileDTO;
  onTitleError: (msg: string) => void;
};

export const DmdcMetadataSection = (
  props: DmdcMetadataSectionProps
): JSX.Element => {
  const { onMetadataChanges, titleAPI, videoFile, onTitleError } = props;

  const [metadata, setMetadata] = useState<Metadata>({
    title: undefined,
    season: undefined,
    episode: undefined,
    version: undefined,
    security: undefined,
    assetName: undefined,
    screenerType: undefined,
    permanentHoldback: false,
    noQCRequired: undefined,
    context: "DMDC",
  });

  const toasterContext = useContext(ToastContext);
  const [searchValue, setSearchValue] = useState("");
  const [debouncer, setDebouncer] = useState<NodeJS.Timeout>();
  const [lockTitleFields, setLockTitleFields] = useState<boolean>(true);
  const [loadingTitles, setLoadingTitles] = useState<boolean>(false);
  const [loadingSeasonEpisodes, setLoadingSeasonEpisodes] =
    useState<boolean>(false);
  const [loadingSeasons, setLoadingSeasons] = useState<boolean>(false);
  const suggestions = useArray<TitleItem>([]);

  const [selectedTitle, setSelectedTitle] = useState<DmdcChildEntity>();

  const seasons = useArray<DmdcChildEntity>([]);
  const [selectedSeason, setSelectedSeason] = useState<DmdcChildEntity>();

  const episodes = useArray<DmdcChildEntity>([]);
  const [selectedEpisode, setSelectedEpisode] = useState<DmdcChildEntity>();

  const versions = useArray<TitleVersion>([]);
  const [versionedProduct, setVersionedProduct] = useState<DmdcChildEntity>();
  const [selectedVersion, setSelectedVersion] = useState<TitleVersion>();

  const [assetName, setAssetName] = useState<string | undefined>("");

  const [forensicWatermark, setForensicWatermark] =
    useState<string>("Let system decide");

  const [selectedScreenerType, setSelectedScreenerType] =
    useState<DSScreenerTypeOptions>();

  const [permanentHoldback, setPermanentHoldback] = useState<boolean>();
  const [noQCRequired, setNoQCRequired] = useState(true);

  const populateTitleFields = (title: TitleItem): void => {
    const rootTitle = !SeriesChildren.includes(title.type.id)
      ? title
      : (title.series as TitleItem);

    const rootTitleMapped = mapRootTitle(rootTitle);
    setSelectedTitle(rootTitleMapped);
    let versionedTitle = rootTitleMapped;

    if (title.type.id === ProductType.SEASON) {
      const seasonTitle = mapSeasonTitle(title);
      versionedTitle = seasonTitle;
      seasons.set([seasonTitle]);
      setSelectedSeason(seasonTitle);
    }

    if (EpisodeTitles.includes(title.type.id)) {
      const episodeTitle = mapEpisodeTitle(title);
      versionedTitle = episodeTitle;
      episodes.set([episodeTitle]);
      setSelectedEpisode(episodeTitle);

      if (title.season) {
        const seasonTitle = mapSeasonTitle(title.season);
        seasons.set([seasonTitle]);
        setSelectedSeason(seasonTitle);
      }
    }
    setVersionedProduct(versionedTitle);
  };

  const searchTitle = async (): Promise<void> => {
    setLoadingTitles(true);
    try {
      const v: SearchResult = await titleAPI.searchTitleByKeyword(searchValue);
      suggestions.set(v.items);
    } catch (error: any) {
      const duration = 6000;
      (toasterContext as ToastContextDef).actions.push({
        duration,
        closeable: true,
        type: ToastType.ERROR,
      });
    } finally {
      setLoadingTitles(false);
    }
  };

  const searchTitleById = async (): Promise<void> => {
    setLoadingTitles(true);
    try {
      const title: TitleItem = (
        await titleAPI.searchTitleByKeywordOrVersion(searchValue)
      ).items[0];
      if (!title) {
        return;
      }
      suggestions.set([title]);
      populateTitleFields(title);
    } catch (error: any) {
      const duration = 6000;
      (toasterContext as ToastContextDef).actions.push({
        duration,
        closeable: true,
        type: ToastType.ERROR,
      });
    } finally {
      setLoadingTitles(false);
    }
  };

  const getAutoCompleteItemDisplayValue = (
    title: DmdcChildEntity | undefined
  ): string => {
    if (!title) {
      return "";
    }
    const titleMapped = mapRootTitle(title as unknown as TitleItem);
    const titleName = titleMapped?.name || "";
    const releaseYear = titleMapped?.releaseDate
      ? `(${moment(titleMapped?.releaseDate).year()})`
      : "";
    const titleType = titleMapped?.type || "";
    const titleTypeCapitalized =
      titleType.charAt(0).toUpperCase() + titleType.slice(1);
    const cpmId = titleMapped?.productId?.toString() || "";
    return titleMapped
      ? `${titleName} ${releaseYear} - ${titleTypeCapitalized} [${cpmId}]`
      : "";
  };

  const isDisableVersionsField = (): boolean => {
    const requireEpisode =
      ParentProductTypes.includes(
        selectedTitle?.productTypeId as ProductType
      ) && !selectedEpisode;
    return (
      versions.array.length === 0 ||
      lockTitleFields ||
      requireEpisode ||
      !isVersionApplicable()
    );
  };

  useEffect(() => {
    clearTimeout(debouncer);
    setDebouncer(
      setTimeout(() => {
        if (
          searchValue !== "" &&
          searchValue !== getAutoCompleteItemDisplayValue(selectedTitle) &&
          searchValue !== getAutoCompleteItemDisplayValue(selectedSeason) &&
          searchValue !== getAutoCompleteItemDisplayValue(selectedEpisode)
        ) {
          void searchTitle();
        }
      }, 1000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onChangeSearchQuery = (value: string): void => {
    setSearchValue(value);
  };

  const onKeyDownSearchQuery = (event: any): void => {
    if (event.keyCode === 13) {
      void searchTitleById();
    }
  };

  /** *********************************************************************** */
  const getChildTitles = async (
    childId: string,
    type: "seasons" | "episodes"
  ): Promise<void> => {
    let loadingChildMethod = setLoadingSeasonEpisodes;
    let childArray = episodes;
    let mapMethod = mapEpisodeTitle;
    if (type === "seasons") {
      loadingChildMethod = setLoadingSeasons;
      childArray = seasons;
      mapMethod = mapSeasonTitle;
    }

    try {
      loadingChildMethod(true);
      const childTitles = await titleAPI.searchChildTitles(childId);
      childArray.set(
        childTitles.items
          .map(mapMethod)
          .sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
      );
    } catch (error: any) {
      const duration = 6000;
      (toasterContext as ToastContextDef).actions.push({
        duration,
        closeable: true,
        type: ToastType.ERROR,
      });
    } finally {
      loadingChildMethod(false);
    }
  };

  const getTitlesByVersion = async (versionId: number) => {
    try {
      const title = (await titleAPI.searchTitleByVersionId(versionId)).items[0];
      if (!title) {
        onTitleError(
          "There is no title that matches with the version present in the file name, please select a different one"
        );
        return;
      }
      populateTitleFields(title);
      const mappedVersion = mapVersion(
        title?.versions?.find(
          (version) => version.versionId === versionId
        ) as Version
      );
      setSelectedVersion(mappedVersion);
    } catch (error: any) {
      const duration = 6000;
      (toasterContext as ToastContextDef).actions.push({
        duration,
        closeable: true,
        type: ToastType.ERROR,
      });
    }
  };

  useEffect(() => {
    if (selectedSeason) {
      void getChildTitles(selectedSeason.id as string, "episodes");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeason]);

  const onSeasonSelectChange = (name: string, value: DmdcChildEntity): void => {
    if (value?.id !== selectedSeason?.id) {
      episodes.clear();
      versions.clear();
      setSelectedEpisode(undefined);
      setSelectedVersion(undefined);
      setSelectedSeason(value);
    }
  };

  const isVersionApplicable = useCallback((): boolean => {
    const isFullProgram =
      selectedScreenerType?.value === FinalScreener.value ||
      selectedScreenerType?.value === PreliminaryScreener.value;

    const isEventType = EventProductTypes.includes(
      selectedTitle?.productTypeId as ProductType
    );
    if (isEventType) {
      return false;
    }
    if (isFullProgram) {
      return true;
    }

    return false;
  }, [selectedScreenerType, selectedTitle?.productTypeId]);

  /** *********************************************************************** */

  const getChildren = async (): Promise<void> => {
    const { id, productTypeId } = selectedTitle || {};
    if (id && productTypeId && ParentProductTypes.includes(productTypeId)) {
      const childType =
        productTypeId === ProductType.MINISERIES ? "episodes" : "seasons";
      await getChildTitles(id, childType);
    }
  };

  useEffect(() => {
    void getChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTitle]);

  const onTitleSelected = (title: TitleItem): void => {
    seasons.clear();
    episodes.clear();
    versions.clear();
    setSelectedSeason(undefined);
    setSelectedEpisode(undefined);
    setSelectedVersion(undefined);
    void populateTitleFields(title);
  };

  /** *********************************************************************** */

  useEffect(() => {
    if (selectedEpisode) {
      setVersionedProduct(selectedEpisode);
    }
  }, [selectedEpisode]);

  const onEpisodeSelectChange = (
    name: string,
    value: DmdcChildEntity
  ): void => {
    versions.clear();
    setSelectedVersion(undefined);
    setSelectedEpisode(value);
    setVersionedProduct(undefined);
  };

  /** *********************************************************************** */

  useEffect(() => {
    if (versionedProduct) {
      versions.set(versionedProduct.versions || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionedProduct]);

  const onVersionSelected = (name: string, value: TitleVersion): void => {
    setSelectedVersion(value);
  };

  /** *********************************************************************** */

  const onAssetNameChange = (value: string): void => {
    setAssetName(value);
  };

  /** *********************************************************************** */

  const onScreenerTypeSelected = (
    name: string,
    value: DSScreenerTypeOptions
  ): void => {
    setSelectedScreenerType(value);
  };

  /** *********************************************************************** */

  const onForensicWatermarkSelected = (name: string, value: string): void => {
    setForensicWatermark(value);
  };

  /** *********************************************************************** */

  const onPermanentHoldbackChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPermanentHoldback(e.target.checked);
  };

  /** *********************************************************************** */
  /**
   * Disabled feature to control the No QC required checkbox
  useEffect(() => {
    const shouldMarkNoQCCheckbox = [
      TrailerForRatings.value,
      PreliminaryScreener.value,
    ].includes(selectedScreenerType?.value as DSScreenerTypeValues);
    setNoQCRequired(shouldMarkNoQCCheckbox);
  }, [selectedScreenerType]);
  */

  const onNoQCRequiredChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setNoQCRequired(e.target.checked);
  };

  /** *********************************************************************** */

  const isValidMetadata = (): boolean => {
    if (metadata.title?.productId === undefined) return false;
    if (
      metadata.title.type === "series" &&
      metadata.season === undefined &&
      metadata.episode === undefined
    )
      return false;
    if (metadata.version === undefined && isVersionApplicable()) return false;
    if (metadata.screenerType === undefined) return false;
    if (metadata.security === undefined) return false;
    return true;
  };

  /** *********************************************************************** */

  useEffect(() => {
    if (!videoFile?.name) {
      seasons.clear();
      episodes.clear();
      versions.clear();
      setSelectedSeason(undefined);
      setSelectedEpisode(undefined);
      setSelectedVersion(undefined);
      setSearchValue("");
      setSelectedTitle(undefined);
      setSelectedScreenerType(undefined);
      setLockTitleFields(false);
      return;
    }
    const parser = new FileNameParser(videoFile?.name);
    const versionId = parser.getVersion();
    const fileDetails = parser.getFileDetails();

    const isWIP = (fileDetails?.wip || "").toUpperCase() === "WIP";
    const type = (fileDetails?.type || "").toUpperCase();
    const isSCRN =
      (fileDetails?.videoSpecification || "").toUpperCase() === "SCRN";

    let screenerType: DSScreenerTypeOptions | undefined = undefined;
    if (isSCRN) {
      if (type === "FP") {
        screenerType = isWIP ? PreliminaryScreener : FinalScreener;
      }
      if (type === "TR") {
        screenerType = isWIP ? TrailerForRatings : FinalTrailer;
      }
      if (type === "PR") {
        screenerType = Promo;
      }
    }

    setSelectedScreenerType(screenerType);
    if (versionId) {
      setLockTitleFields(true);
      void getTitlesByVersion(versionId);
    } else {
      setLockTitleFields(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoFile]);

  useEffect(() => {
    if (onMetadataChanges) {
      onMetadataChanges(isValidMetadata() ? metadata : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  useEffect(() => {
    setMetadata(
      (current: Metadata): Metadata => ({
        ...current,
        title: mapChildEntity(selectedTitle),
        season: mapChildEntity(selectedSeason),
        episode: mapChildEntity(selectedEpisode),
        version: isVersionApplicable() ? selectedVersion : undefined,
        security: forensicWatermark,
        assetName: assetName || undefined,
        screenerType: selectedScreenerType?.value || undefined,
        permanentHoldback: permanentHoldback || undefined,
        noQCRequired: noQCRequired || undefined,
      })
    );
  }, [
    selectedEpisode,
    selectedSeason,
    selectedTitle,
    selectedVersion,
    assetName,
    selectedScreenerType,
    permanentHoldback,
    noQCRequired,
    isVersionApplicable,
    forensicWatermark,
  ]);

  return (
    <div className="uw-row uw-col">
      <div className="uw-row">
        <div className="uw-col">
          <Field label="Screener Type*">
            <Select
              value={selectedScreenerType?.name}
              onOptionChange={onScreenerTypeSelected}
            >
              {screenerTypeOptions.map((screenerType) => (
                <Option
                  key={screenerType.value}
                  name={screenerType.name}
                  value={screenerType}
                  hideOnSelect
                />
              ))}
            </Select>
          </Field>
        </div>
        <div className="uw-col">
          <Field label="Initial Asset Name">
            <Input onChange={onAssetNameChange} value={assetName} />
          </Field>
        </div>
        <div className="uw-col">
          <Field label="Forensic Watermark*">
            <Select
              onOptionChange={onForensicWatermarkSelected}
              value="Let system decide"
            >
              <Option
                name="Let system decide"
                value="Let system decide"
                hideOnSelect
              />
              <Option name="Yes" value="Yes" hideOnSelect />
            </Select>
          </Field>
        </div>
        <div className="uw-col">
          <Field label="Permanent Holdback">
            <input
              name="select-radio"
              type="checkbox"
              checked={permanentHoldback}
              onChange={onPermanentHoldbackChange}
            />
          </Field>
        </div>
        <div className="uw-col">
          <Field label="No QC Required" disabled={true}>
            <input
              name="select-radio"
              type="checkbox"
              checked={noQCRequired}
              onChange={onNoQCRequiredChange}
            />
          </Field>
        </div>
      </div>
      <div className="uw-row">
        <Field label="Title*" disabled={lockTitleFields}>
          <Autocomplete
            value={getAutoCompleteItemDisplayValue(selectedTitle)}
            loading={loadingTitles}
            onChange={onChangeSearchQuery}
            onSelect={onTitleSelected}
            onKeyDown={onKeyDownSearchQuery}
            disabled={lockTitleFields}
            suggestions={suggestions.array}
            stringMapper={(item: DmdcChildEntity) =>
              getAutoCompleteItemDisplayValue(item)
            }
          />
        </Field>
      </div>
      <div className="uw-row">
        <div className="uw-col">
          <SeasonField
            value={selectedSeason?.name}
            getSeasonName={(childEntity: DmdcChildEntity): string =>
              childEntity.name
            }
            disabled={seasons.array.length === 0 || lockTitleFields}
            loading={loadingSeasons}
            onSeasonSelectChange={onSeasonSelectChange}
            seasons={seasons.array}
            required={isVersionApplicable()}
          ></SeasonField>
        </div>
        <div className="uw-col">
          <EpisodeField
            value={selectedEpisode?.name}
            disabled={episodes.array.length === 0 || lockTitleFields}
            loading={loadingSeasonEpisodes}
            onEpisodeSelectChange={onEpisodeSelectChange}
            episodes={episodes.array as TitleDetails[]}
            required={isVersionApplicable()}
          ></EpisodeField>
        </div>
      </div>
      <div className="uw-row">
        <div className="uw-col">
          <VersionField
            value={isVersionApplicable() ? selectedVersion?.name : "N/A"}
            disabled={isDisableVersionsField()}
            onVersionSelected={onVersionSelected}
            versions={versions.array}
            required={isVersionApplicable()}
          ></VersionField>
        </div>
        <div className="uw-col"></div>
      </div>
    </div>
  );
};
